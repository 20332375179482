import { call, put, select, take, takeLatest, all, fork, join, cancel, cancelled } from 'redux-saga/effects'
import { push, replace } from 'connected-react-router'

import * as constants from '../../constants'
import * as viewConstants from './constants'
import * as employeeConstants from 'entities/employee/constants'
import * as employeeApi from 'entities/employee/api'
import * as eventConstants from 'entities/event/constants'
import * as eventApi from 'entities/event/api'
import * as absenceConstants from 'entities/absence/constants'
import * as absenceApi from 'entities/absence/api'

import { fetchDependentData, fetchFilteredRecords } from 'model/sagas'
import { getEmployeeState, getEventState, getAbsenceState } from './selectors'
import { getAccessUser } from 'access/selectors'

function *fetchBaseData() {
  yield call( fetchDependentData, [ 'base' ] )
}

function *fetchEmployee() {
  const user = yield select(getAccessUser)
  if ( user.employee_key ) {
    yield call( employeeApi.getRecord, user.employee_key, employeeConstants.RECORD_LOAD )
  }
}

function* fetchEmployeeRecords( action ) {
  try {
    //if ( debounce )
      //yield delay( debounce )
    const viewState = yield select(getEmployeeState)
    const [ , ] = yield all([
      call( fetchBaseData ),
    ])
    yield call( fetchFilteredRecords, viewState, employeeApi.getRecords, employeeConstants.RECORDS_LOAD )
  } catch (e) {
    console.error( e )
  }
}

function* fetchEventRecords( action ) {
  try {
    //if ( debounce )
      //yield delay( debounce )
    const viewState = yield select(getEventState)
    const [ , ] = yield all([
      call( fetchBaseData ),
    ])
    yield call( fetchFilteredRecords, viewState, eventApi.getRecords, eventConstants.RECORDS_LOAD )
  } catch (e) {
    console.error( e )
  }
}

function* updateEventRecord( action ) {
  try {
    yield call( eventApi.updateRecord, action.payload )
  } catch (e) {
    console.error( e )
  }
}

function* deleteEventRecord( action ) {
  try {
    yield call( eventApi.deleteRecord, action.payload )
  } catch (e) {
    console.error( e )
  }
}

function *eventRecordUpdated( action ) {
  yield put({type: eventConstants.EDIT_MODAL_CLOSE});
  yield call( fetchEventRecords );
}

function* fetchAbsenceRecords( action ) {
  try {
    //if ( debounce )
      //yield delay( debounce )
    const viewState = yield select(getAbsenceState)
    const [ , ] = yield all([
      call( fetchBaseData ),
    ])
    yield call( fetchFilteredRecords, viewState, absenceApi.getRecords, absenceConstants.RECORDS_LOAD )
  } catch (e) {
    console.error( e )
  }
}

function* fetchAbsenceRecord( action ) {
  try {
    const [ , ] = yield all([
      call( fetchBaseData ),
    ])
    yield call( absenceApi.getRecord, action.payload, action.type )
  } catch (e) {
    console.error( e )
  }
}

function* updateAbsenceRecord( action ) {
  try {
    yield call( absenceApi.updateRecord, action.payload )
  } catch (e) {
    console.error( e )
  }
}

function* deleteAbsenceRecord( action ) {
  try {
    yield call( absenceApi.deleteRecord, action.payload )
  } catch (e) {
    console.error( e )
  }
}

function *addAbsence( action ) {
  const record = action.payload;
  yield put({type: absenceConstants.EDIT_MODAL_OPEN, payload: record});
}

function *editAbsence( action ) {
  const record = action.payload;
  yield put({type: absenceConstants.RECORD_LOAD, payload: record.absence_id });
  yield put({type: absenceConstants.EDIT_MODAL_OPEN, payload: record});
}

function *absenceRecordUpdated( action ) {
  yield put({type: absenceConstants.EDIT_MODAL_CLOSE});
  yield call( fetchAbsenceRecords );
}

function *cancelAbsence( action ) {
  yield( put( replace( "/absences" ) ) )
}

function *deleteAbsence( action ) {
  const record = action.payload;
  yield put({type: absenceConstants.DELETE_MODAL_OPEN, payload: record});
}

function *absenceRecordDeleted( action ) {
  yield put({type: absenceConstants.EDIT_MODAL_CLOSE});
  yield put({type: absenceConstants.DELETE_MODAL_CLOSE});
  yield call( fetchAbsenceRecords );
}

function *printAbsence( action ) {
  const record = action.payload;
  yield( put( push( "/absence/"+record.absence_id+"/print" ) ) )
}

function* apiSaga() {
  try {
    console.log( "Saga started" )
    yield join([
      yield fork( fetchEmployee ),
      yield fork( fetchDependentData, [ 'base' ] ),
			yield takeLatest( employeeConstants.RECORDS_LOAD, fetchEmployeeRecords ),
			yield takeLatest( eventConstants.RECORDS_LOAD, fetchEventRecords ),
			yield takeLatest( eventConstants.RECORD_UPDATE, updateEventRecord ),
			yield takeLatest( eventConstants.RECORD_UPDATE+constants.SUCCESS_SUFFIX, eventRecordUpdated ),
			yield takeLatest( eventConstants.RECORD_DELETE, deleteEventRecord ),
			yield takeLatest( eventConstants.RECORD_DELETE+constants.SUCCESS_SUFFIX, eventRecordUpdated ),
			yield takeLatest( absenceConstants.RECORDS_LOAD, fetchAbsenceRecords ),
			yield takeLatest( absenceConstants.RECORDS_PAGE, fetchAbsenceRecords ),
			yield takeLatest( absenceConstants.RECORDS_SORT, fetchAbsenceRecords ),
			yield takeLatest( absenceConstants.RECORDS_SEARCH, fetchAbsenceRecords ),
			yield takeLatest( absenceConstants.RECORDS_DATE, fetchAbsenceRecords ),
			yield takeLatest( absenceConstants.RECORD_LOAD, fetchAbsenceRecord ),
			yield takeLatest( absenceConstants.RECORD_UPDATE, updateAbsenceRecord ),
			yield takeLatest( absenceConstants.RECORD_UPDATE+constants.SUCCESS_SUFFIX, absenceRecordUpdated ),
			yield takeLatest( absenceConstants.RECORD_DELETE, deleteAbsenceRecord ),
			yield takeLatest( absenceConstants.RECORD_DELETE+constants.SUCCESS_SUFFIX, absenceRecordDeleted ),
			yield takeLatest( absenceConstants.ADD, addAbsence ),
			yield takeLatest( absenceConstants.EDIT, editAbsence ),
			yield takeLatest( absenceConstants.CANCEL, cancelAbsence ),
			yield takeLatest( absenceConstants.DELETE, deleteAbsence ),
			yield takeLatest( absenceConstants.PRINT, printAbsence ),
      //yield takeLatest( constants.UPDATE_REFRESH, refreshRecords ),
    ])
  } catch ( error ) {
    console.error( "Saga error", error )
  } finally {
    if ( yield cancelled() )
      console.log( "Saga stopped" )
  }
}

export default function* () {
  while ( yield take( viewConstants.VIEW_MOUNT) ) {
    const apiTask = yield fork(apiSaga)
    yield take( viewConstants.VIEW_UNMOUNT )
    yield cancel(apiTask)
  }
}
