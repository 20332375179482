import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Form, Table } from 'semantic-ui-react';
import { object, number, string, array } from 'yup';
import { reduxForm, formValueSelector, isValid, submit, arrayPush, arrayRemoveAll, change } from 'redux-form'

import { EditModal } from 'components/modals'
import { AddButton, EditButton, UneditButton, DeleteButton, OkButton } from 'components/buttons'
import { FormInput, FormDropDown, FormTextArea, validateSchema } from 'components/forms/redux-form/'
import { getAbsenceTypes, getEmployeeRoles } from 'model/selectors'
import * as organisationApi from 'entities/organisation/api'
import * as usersApi from 'entities/user/api'

const FORM_ID = 'employee';

const schema = object().shape({
  //employee_title:     string()/*.min(2)*/.label("Title"),
  employee_first_name:  string().required().label("First Name"),
  employee_last_name:   string()/*.required()*/.label("Last Name"),
  organisation_id:      number().required().positive().integer().label("Organisation"),
  employee_department:  string().label("Department"),
  employee_role:        string().required().label("Role"),
  user_id:              number().required().label("User"),
  employee_phone:       string().nullable().label("Phone"),
  years:                array().min(1).label("Years"),
});

const EmployeeFormBase = props => {
  const {
    absenceTypes,
    employeeRoles,
    handleSubmit,
    onSubmit,
    dispatch,
    values
  } = props
  console.log( "EFB", props );
  const [ organisations, setOrganisations ] = useState([])
  const [ departments, setDepartments ] = useState([])
  const [ unassignedUsers, setUnassignedUsers ] = useState([])
  const [ editing, setEditing ] = useState(null)
  useEffect( () => {
    organisationApi.getRecords().then( response => {
      setOrganisations( response.data.records )
    })
    usersApi.getRecords().then( response => {
      setUnassignedUsers( response.data.records )
    })
  }, [] )
  const organisation = useMemo( () => organisations.find( organisation => organisation.organisation_id === values.organisation_id ), [ organisations, values.organisation_id ] )
  useEffect( () => {
    if ( !values.employee_key ) {
      if ( !organisation ) {
        dispatch( arrayPush( FORM_ID, "employee_id", undefined ) )
        return
      }
      organisationApi.getNextEmployeeId( organisation.organisation_id ).then( ({ data }) => {
        dispatch( change( FORM_ID, "employee_id", data.employee_id ) )
      })
    }
  }, [ organisation, values.employee_key ] )
  useEffect( () => {
    if ( organisation ) {
      organisationApi.getDepartments( organisation.organisation_id ).then( response => {
        setDepartments( response.data )
      })
    }
  }, [ organisation ] )
  const yearAdd = () => {
    if ( organisation ) {
      organisation.years.filter( year => year.organisation_year_past !== "1" ).some( year => {
        if ( values.years.findIndex( employeeYear => employeeYear.organisation_year === year.organisation_year ) < 0 ) {
          const newYear = {
            employee_id: values.employee_id,
            organisation_id: year.organisation_id,
            organisation_year: year.organisation_year,
            organisation_year_name: year.organisation_year_name,
            employee_days_in_week: values.employee_days_in_week,
            quotas: [
                {
                  employee_id: values.employee_id,
                  organisation_id: year.organisation_id,
                  organisation_year: year.organisation_year,
                  absence_type_id: 'holiday',
                  absence_total: 0,
                  employee_year_max_available: values.employee_default_allowance,
                  employee_year_total_carried_prev: 0,
                },
                {
                  employee_id: values.employee_id,
                  organisation_id: year.organisation_id,
                  organisation_year: year.organisation_year,
                  absence_type_id: 'sickness',
                  absence_total: 0,
                  employee_year_max_available: 0,
                  employee_year_total_carried_prev: 0,
                },
            ],
          }
          dispatch( arrayPush( FORM_ID, "years", newYear ) )
          // setEditing( year.organisation_year )
          return true
        }
        return false
      })
    }
  }
  useEffect( () => {
    if ( !values.employee_key ) {
      dispatch( arrayRemoveAll( FORM_ID, "years" ) )
      if ( organisation && !values.employee_key && values.employee_default_allowance, values.employee_days_in_week ) {
        organisation.years.filter( year => year.organisation_year_past !== "1" ).forEach( year => {
          const newYear = {
            employee_id: values.employee_id,
            organisation_id: year.organisation_id,
            organisation_year: year.organisation_year,
            organisation_year_name: year.organisation_year_name,
            employee_days_in_week: values.employee_days_in_week,
            quotas: [
              {
                employee_id: values.employee_id,
                organisation_id: year.organisation_id,
                organisation_year: year.organisation_year,
                absence_type_id: 'holiday',
                absence_total: 0,
                employee_year_max_available: values.employee_default_allowance,
                employee_year_total_carried_prev: 0,
              },
              {
                employee_id: values.employee_id,
                organisation_id: year.organisation_id,
                organisation_year: year.organisation_year,
                absence_type_id: 'sickness',
                absence_total: 0,
                employee_year_max_available: 0,
                employee_year_total_carried_prev: 0,
              },
            ],
          }
          dispatch( arrayPush( FORM_ID, "years", newYear ) )
        })
      }
    }
  }, [ editing, organisation, values.employee_key, values.employee_default_allowance, values.employee_days_in_week ])
  const employeeRoleOptions = useMemo( () => employeeRoles.map( role => ({ text: role, value: role }) ), [ employeeRoles ] )
  const organisationOptions = useMemo( () => organisations.map( org => ({ text: org.organisation_name, value: org.organisation_id }) ), [ organisations ] )
  const departmentOptions = useMemo( () => departments.map( dept => ({ text: dept, value: dept }) ), [ departments ] )
  const userOptions = useMemo( () => unassignedUsers.filter( user => user.user_id > 1 && (values.user_id ? true : user.user_assigned === "0") ).map( user => ({ text: user.user_email, value: user.user_id }) ), [ unassignedUsers ] )
  const allYearsAssigned = useMemo( () => organisation && (values.years.length >= organisation.years.filter( year => year.organisation_year_past !== "1" ).length ), [ organisation, values.years ] )
  const canAddYears = useMemo(
    () => !allYearsAssigned && values.organisation_id && values.employee_default_allowance && values.employee_days_in_week,
    [allYearsAssigned, values.organisation_id, values.employee_default_allowance, values.employee_days_in_week]
  )

  return (
    <Form success={props.valid} warning={props.warning} error={props.invalid} onSubmit={()=>handleSubmit(onSubmit)}>
      <Form.Group>
        <FormInput name="employee_first_name" prompt="First Name" placeholder="First Name" width={4}/>
        <FormInput name="employee_last_name" prompt="Last Name" placeholder="Last Name" width={4}/>
        <FormDropDown
          name="organisation_id"
          prompt="Organisation"
          placeholder="Organisation"
          selection
          clearable
          options={organisationOptions}
          fluid
          width={4}
          disabled={!!values.employee_key}
        />
        <FormDropDown
          name="employee_department"
          prompt="Department"
          placeholder="Department"
          selection
          search
          allowAdditions
          onAddItem={( _e, { value }) => setDepartments( departments.concat( value ).sort() )}
          clearable
          options={departmentOptions}
          fluid
          width={4}
          disabled={!values.organisation_id}
        />
      </Form.Group>
      <Form.Group>
        <FormInput name="employee_nickname" prompt="Known As" placeholder="Known As" width={3}/>
        <FormDropDown
          name="employee_role"
          prompt="Employee Role"
          placeholder="Employee Role"
          selection
          clearable
          options={employeeRoleOptions}
          fluid
          width={3}
        />
        <FormDropDown
          name="user_id"
          prompt="User"
          placeholder="User"
          selection
          clearable
          options={userOptions}
          fluid
          disabled={!!values.employee_key}
          width={5}
        />
        <FormInput
          name="employee_default_allowance"
          prompt="Default Holiday"
          placeholder="Holiday Days"
          width={2}
        />
        <FormInput
          name="employee_days_in_week"
          prompt="Default Days/Week"
          placeholder="Days per Week"
          width={3}
        />
      </Form.Group>
      <Table id="employee_allowances" size="small" compact="very" selectable striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell content="Year"/>
            {absenceTypes.map( type => {
              return <Table.HeaderCell key={type.absence_type_id} className="amount" content={type.absence_type_name}/>
            })}
            <Table.HeaderCell content="Notes"/>
            <Table.HeaderCell><AddButton onClick={() => yearAdd()} disabled={!canAddYears} /></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
        {Object.values(values.years).map( (year,index) =>
          <Table.Row key={year.organisation_year}>
            {editing!==year.organisation_year&&
            <Fragment>
              <Table.Cell>{year.organisation_year_name}</Table.Cell>
              {absenceTypes.map( type => {
                const quota = year.quotas.find( quota => type.absence_type_id === quota.absence_type_id )
                return(
                  <Fragment key={type.absence_type_id}>
                    <Table.Cell className="amount">{quota !== undefined && (type.absence_type_limited === "1" ? `${quota.absence_total} / ( ${quota.employee_year_max_available} + ${quota.employee_year_total_carried_prev} )` : quota.absence_total)}</Table.Cell>
                  </Fragment>
                )
              })}
              <Table.Cell />
              <Table.Cell>
                <EditButton onClick={()=>setEditing(year.organisation_year)} disabled={!!editing}/>
                {/* <DeleteButton onClick={()=>employeeYearDelete(index)} disabled={!!editing}/> */}
              </Table.Cell>
            </Fragment>
            }
            {editing===year.organisation_year&&
            <Fragment>
              <Table.Cell>{year.organisation_year_name}</Table.Cell>
              {absenceTypes.map( type => {
                const quotaIndex = year.quotas.findIndex( quota => quota.absence_type_id === type.absence_type_id )
                return(
                  <Table.Cell key={type.absence_type_id} className="amount">
                    <FormInput
                      name={`years.${index}.quotas.${quotaIndex}.employee_year_max_available`}
                      prompt="Allowance"
                      placeholder="Days"
                      disabled={type.absence_type_limited !== "1"}
                      width={16}
                    />
                  </Table.Cell>
                )
              })}
              <Table.Cell className="amount">
                <FormInput
                  name={`years.${index}.employee_days_in_week`}
                  prompt="Days/Week"
                  placeholder="Days"
                  width={16}
                />
              </Table.Cell>
              <Table.Cell>
                <OkButton
                  onClick={()=>setEditing(null)}
                />
              </Table.Cell>
            </Fragment>
            }
          </Table.Row>
        )}
        </Table.Body>
      </Table>
      <FormTextArea name="employee_notes" placeholder="Notes" rows={4}/>
    </Form>
  )
}

const valueSelector = formValueSelector(FORM_ID);

const EmployeeForm = reduxForm({
  form: FORM_ID,
  validate: validateSchema(schema)
})(connect(store=>({
  absenceTypes: getAbsenceTypes(store),
  employeeRoles: getEmployeeRoles(store),
  values: valueSelector(store,
    'employee_id',
    'employee_key',
    'employee_days_in_week',
    'employee_default_allowance',
    'organisation_id',
    'years',
    'user_id',
  ),
}),
  null
)(EmployeeFormBase))

const EditEmployeeModalBase = props => {
  const editing = props.record && props.record.employee_id;
  const title = (editing?"Edit":"Create")+" Employee"+(editing?(" "+props.record.employee_id):"");
  return(
    <EditModal show={props.show} toggle={props.toggle} title={title} canSubmit={()=>props.valid} onSubmit={props.doSubmit}>
      <EmployeeForm initialValues={props.record} onSubmit={props.onChange}/>
    </EditModal>
  )
}

const formActions = dispatch => {
  return {
    doSubmit: () => {
      dispatch(submit(FORM_ID));
    },
  }
}

export default connect(store=>({
  valid: isValid(FORM_ID)(store),
}),formActions)(EditEmployeeModalBase)
