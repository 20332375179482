import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, Segment, Form } from 'semantic-ui-react';
import { Formik } from 'formik'
import { object, string, boolean, date } from 'yup';

import { EditModal } from 'components/modals'
import { FormInput, FormTextArea, FormDropDown, FormDateTimePicker, FormCheckBox } from 'components/forms/formik/'
import { /*canEdit, */getDataModel } from 'model/selectors'
import viewActions from './actions'

const eventSchema = object().shape({
  event_start:     date().required().label("Start"),
  event_end:       date().nullable().label("End"),
  event_has_time:  boolean().required().label("Has Time"),
  event_type:      string().required().label("Type"),
  event_class:     string().required().label("Class"),
  event_title:     string().required().label("Title"),
  event_notes:     string().nullable().label("Notes"),
});

class EventFormBase extends Component {
  render() {
    const { values: record, dataModel } = this.props

    console.log( "EFB RND", this.state, this.props )
    const eventTypes = (dataModel.event_types || []).map( eventType => ({ value: eventType, text: eventType }) )

    return (
      <Form success={this.props.valid} warning={this.props.warning} error={this.props.invalid}>
        <Segment attached="top">
          <Form.Group>
            <FormInput
              type="test"
              name="event_title"
              prompt="Title"
              required
              width={16}
            />
          </Form.Group>
          <Form.Group>
            <FormDropDown
              name="event_type"
              prompt="Type"
              required
              selection
              options={eventTypes}
              width={2}
              fluid
            />
            <FormInput
              type="text"
              name="event_class"
              prompt="Class"
              width={3}
            />
            <FormDateTimePicker
              name="event_start"
              prompt="Event Start"
              required
              time={record.event_has_time!=="0"}
              displayFormat={record.event_has_time!=="0"?"dd D MMM, YYYY h:mm A":"dddd D MMM, YYYY"}
              width={5}
            />
            <FormDateTimePicker
              name="event_end"
              prompt="Event End"
              required
              time={record.event_has_time!=="0"}
              displayFormat={record.event_has_time!=="0"?"dd D MMM, YYYY h:mm A":"dddd D MMM, YYYY"}
              width={5}
            />
            <FormCheckBox
              name="event_has_time"
              prompt="Has Time"
              trueValue="1"
              falseValue="0"
            />
          </Form.Group>
        </Segment>
        <Header block attached>
          Notes
        </Header>
        <Segment attached="bottom">
          <FormTextArea
            name="event_notes"
          />
        </Segment>
      </Form>
    )
  }
}

const EventForm = connect(store=>({
  dataModel: getDataModel(store),
}),
  null
)(EventFormBase)

class EditEventModalBase extends Component {
  render() {
    console.log( "EEM", this.props )
    const { record, show, toggle } = this.props
    if ( !record )
      return null;
    const editing = record && record.event_id;
    const title = (editing?"Edit":"Create")+" Event"+(editing?(" "+record.event_id):"");
    return(
      <Formik
        initialValues={record}
        isInitialValid={record.event_id!==undefined}
        validationSchema={eventSchema}
        onSubmit={this.props.onChange}
        render={ props => {
          return(
            <EditModal
              show={show}
              toggle={toggle}
              title={title}
              canSubmit={()=>props.isValid}
              onSubmit={props.handleSubmit}
              onDelete={this.props.onDelete}
            >
              <EventForm {...props}/>
            </EditModal>
          )
        }}
      />
    )
  }
}

export const EditEventModal = connect( store=>({
  //canEdit: canEdit(store),
}),
{
  ...viewActions
})(EditEventModalBase)
