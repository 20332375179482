import React, { Fragment } from 'react'
import { connect } from 'react-redux'

import { ConfirmModal } from 'components/modals'
import { EditEventModal } from './forms'
import actions from './actions'

const DialogsBase = ( props ) => {
  return (
    <Fragment>
      <EditEventModal
        record={props.editModal.record}
        show={props.editModal.open}
        toggle={()=>props.eventEditModalClose()}
        onChange={props.eventRecordUpdate}
        onDelete={()=>props.eventRecordDelete(props.editModal.record)}
      />
      <ConfirmModal
        show={props.deleteModal.open}
        title="Confirm Delete Event"
        message="Are you sure you want to delete this event?"
        onSubmit={()=>props.eventRecordDelete(props.deleteModal.record)}
        toggle={()=>props.eventDeleteModalClose()}
      />
    </Fragment>
  )
}

export default connect(
  null,{
  ...actions,
})(DialogsBase);
