import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { Grid, Container } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import AppContext from './context'

import AppNav from './nav'
import AccessRoutes from 'access/routes'
import AppRoutes from './routes'
import { Modals, Notifications } from 'dialogs/'

import appActions from './actions'
import accessActions from 'access/actions'
import { getAccessUser } from 'access/selectors'

import '../scss/index.scss';

const App = props => {
  //console.log( "APP", props )

  const { user, history, accessCheck } = { ...props }

  const [initialUrl,setInitialUrl] = useState(props.history.location.pathname)

  const hasUser = useMemo( ()=>!!user, [ user ] )
  const activeUser = useMemo( ()=>user && user.user_state === 'active', [ user ] )

  const context = useMemo( () => ({
  }), [] )

  useEffect( () => {
    accessCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  // Don't run this effect on first update
  const firstUpdate = useRef(true)
  useEffect( () => {
    console.log( "UE", firstUpdate.current, hasUser, user, initialUrl )
    if ( firstUpdate.current )
    {
      firstUpdate.current = false
      return
    }
    if ( hasUser )
    {
      // Logged in
      if ( initialUrl != "/login") {
        history.replace( initialUrl )
      }
    }
    else
    {
      // Logged out
      setInitialUrl('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ hasUser, initialUrl ] )

  return(
    <AppContext.Provider value={context}>
      <Grid padded="horizontally">
        <Helmet defaultTitle="Holiday Admin" titleTemplate={"%s"}/>
        <Container className="header" fluid>
          {activeUser &&
          <AppNav user={user}/>
          }
        </Container>
        <Container className="app" fluid>
          {activeUser &&
          <Notifications/>
          }
          {!activeUser &&
          <AccessRoutes/>
          }
          {activeUser &&
          <AppRoutes user={user}/>
          }
          <Modals/>
        </Container>
      </Grid>
    </AppContext.Provider>
  )
}

//export default App

export default withRouter(connect(store=>({
  user: getAccessUser(store),
}),{
  ...appActions,
  //...dialogActions,
  ...accessActions,
})(App));
